import {AuthModule} from "./auth/auth.module";
import {NgModule, Optional, SkipSelf} from "@angular/core";

const coreComponents = [
  AuthModule
];

@NgModule({
  imports: [
    coreComponents
  ],
  exports: [
    coreComponents
  ]

})
export class CoreModule {

  constructor(
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
}
